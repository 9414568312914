.filter-category{
	display: block;
	padding: 7px 0;
	color: #373a3c;
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
	transition: .3s;
	&:hover, &.active{
		color: $color-general;
	}	
	&:first-child{
		padding-top: 0;
	}
}