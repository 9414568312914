.review-block{
	position: relative;
	margin: 0 0 50px;
	padding: 30px 35px;
	background-color: #f5f8fb;
	&:after{
		content: "";
		position: absolute;
		z-index: -1;
		top: 15px;
		left: 15px;
		width: 100%;
		height: 100%;
		border: 6px solid $color-general;
	}

	&__header{
		display: flex;
	}
	&__thumb{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 65px;
		height: 65px;
		border-radius: 100%;
		background-size: cover;
		background-position: center;
	}
	&__info{
		padding: 8px 0 0 20px;
	}
	&__date{
		display: block;
		color: $color-general-dark;
		font-size: 15px;
		font-weight: 400;
	}
	&__name{
		display: block;
		color: #333;
		font-size: 18px;
		font-weight: 700;
	}
	&__content{
		margin: 14px 0 0;
		p{
			color: #333;
			font-size: 15px;
			font-weight: 400;
			line-height: 22px;
		}
	}
	&__images{
		display: flex;
		flex-wrap: wrap;
	}
	&__img{
		display: block;
		position: relative;
		width: 65px;
		height: 65px;
		margin: 0 10px 10px 0;
		background-size: cover;
		background-position: center;
		cursor: pointer;
		$img: &;
		&:hover{
			#{$img}-hover{
				opacity: 1;
			}
		}
		&-hover{
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			width: 33px;
			height: 33px;
			border-radius: 100%;
			background-color: $color-general;
			opacity: 0;
			transition: opacity .3s ease-in-out;
			&:before, &:after{
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				width: 13px;
				height: 1px;
				background-color: #fff;
			}
			&:after{
				transform: rotate(90deg);
			}
		}
	}
}



@media(max-width: 767px){
	.review-block:after{
		top: 10px;
		left: 10px;
		border-width: 3px;
	}
}

@media(max-width: 575px){
	.review-block{
		padding: 20px 22px;
		margin: 0 0 30px;
		&__info{
			padding: 10px 0 0 10px;
		}
		&__date{
			font-size: 14px;
		}
		&__name{
			font-size: 15px;
		}
		&__content{
			margin: 10px 0 0;
			p{
				font-size: 14px;
				line-height: 20px;
			}
		}
		&__images{
			padding: 10px 0 0;
		}
	}
}