.back-to-catalog{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 174px;
	padding: 8px 0;
	margin: 5px 0 15px;
	border-radius: 15px;
	border: 1px solid #e5e5e5;
	background-color: #fff;
	color: #373a3c;
	font-size: 12px;
	font-weight: 400;
	line-height: 13px;
	transition: .3s;

	&:hover{
		color: #fff;
		background-color: $color-general;
		border-color: $color-general;
		> svg{
			fill: #fff;
		}
	}
	> svg{
		margin: 0 6px 0 0;
		fill: $color-general;
		transition: .3s;
	}
}