.catalog-button {
    $this: &;
    position: relative;
    display: inline-flex;
    // height: 60px;

	&:before, &:after{
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 1px;
		height: 40px;
		background-color: #ececec;
	}
	&:before{
		left: 0;
	}
	&:after{
		right: 0;
	}

    &-link {
        display: flex;
        align-items: center;
        height: 100%;
		padding: 0 25px;
		transition: background-color 200ms ease;
	}
	&-icon{
		display: flex;
		align-items: center;
		margin: 0 10px 0 0;
		> svg{
			fill: $color-general;
			transform: rotate(-90deg);
			transition: transform .4s ease-in-out;
		}
	}

    &-title {
        display: inline-block;
		color: #333;
		font-size: 16px;
		font-weight: 700;
		transition: .2s;
    }

	&__menu-arrow{
		display: flex;
		position: absolute;
		bottom: -14px;
		left: 85px;
		opacity: 0;
		> svg{
			fill: $color-general;
		}
	}

    .catalog-menu {
        display: none;
    }

    &:hover & {
		&-title{
			color: $color-general;
		}
        &-link {
            background-color: rgba(#fff, 0.2);
		}
		&-icon{
			> svg{
				transform: rotate(45deg);
			}
		}
		&__menu-arrow{
			opacity: 1;
		}
    }

    &:hover {
        .catalog-menu {
            display: block;
        }
    }
}