.simple-button{
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 100%;
	border-radius: 30px;
	border: 2px solid $color-general;
	background-color: $color-general;
	outline: none;
	color: #fff;
	font-size: 18px;
	font-weight: 700;
	transition: .3s;
	cursor: pointer;
	&:hover{
		color: $color-general;
		background-color: transparent;
	}
}