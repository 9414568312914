.simple-form{
	position: relative;
	$this: &;

	> * {
		&:not(.simple-form__notification){
			transition: .2s ease-in-out;
		}
	}
	&.load{
		> *{
			&:not(.simple-form__notification){
				opacity: 0;
			}
		}
		#{$this}__loader{
			display: flex;
		}
	}
	&.success{
		> *{
			&:not(.simple-form__notification){
				opacity: 0;
			}
		}
		#{$this}__success{
			display: flex;
		}
	}

	&__row{
		display: flex;
		align-items: center;
		justify-content: space-between;
		#{$this}__input{
			min-width: calc(50% - 5px);
			width: calc(50% - 5px);
		}
	}
	&__input{
		height: 50px;
	}
	&__textarea{
		height: 90px;
		resize: none;
	}
	&__input, &__textarea{
		display: block;
		width: 100%;
		padding: 12px 24px;
		border-radius: 30px;
		background-color: #fff;
		border: 1px solid #dbe6f0;
		margin-bottom: 10px;
		color: #6a6a6a;
		font-size: 16px;
		font-weight: 400;
		&::-webkit-input-placeholder { /* WebKit, Blink, Edge */
			color:    #8e8e8e;
		}
		&:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
		   color:    #8e8e8e;
		   opacity:  1;
		}
		&::-moz-placeholder { /* Mozilla Firefox 19+ */
		   color:    #8e8e8e;
		   opacity:  1;
		}
		&:-ms-input-placeholder { /* Internet Explorer 10-11 */
		   color:    #8e8e8e;
		}
		&::-ms-input-placeholder { /* Microsoft Edge */
		   color:    #8e8e8e;
		}
		&::placeholder { /* Most modern browsers support this now. */
		   color:    #8e8e8e;
		}
	}
	&__button{
		&-submit{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 60px;
			border-radius: 30px;
			border: 1px solid $color-general;
			background-color: $color-general;
			color: #fff;
			font-size: 18px;
			font-weight: 700;
			transition: .3s;
			cursor: pointer;
			&:hover{
				background-color: transparent;
				color: $color-general;
			}
		}
	}
	&__error{
		display: block;
		margin: 0 0 8px;
		color: #f73333;
		font-size: 14px;
	}
	&__acceptance{
		display: block;
		margin: 10px 8px 0;
		color: #ccc;
		font-size: 12px;
		font-weight: 400;
		a{
			text-decoration: underline;
			color: #ccc;
			transition: .3s;
			&:hover{
				color: $color-general;
			}
		}
	}
	&__file{
		padding: 4px 10px;
		margin-bottom: 15px;
		$file: &;

		&.active{
			#{$file}-info{
				display: flex;
			}
			#{$file}-upload{
				display: none;
			}
		}

		&-info{
			display: none;
			align-items: center;
			&-remove{
				display: block;
				position: relative;
				width: 18px;
				height: 18px;
				margin: 0 10px 0 0;
				cursor: pointer;
				&:after, &:before{
					content: "";
					position: absolute;
					top: 50%;
					left: 0;
					width: 100%;
					height: 2px;
					background-color: $color-general;
				}
				&:before{
					transform: rotate(-45deg);
				}
				&:after{
					transform: rotate(45deg);
				}
			}
			&-title{
				display: block;
				color: #6a6a6a;
				font-size: 16px;
				font-weight: 400;
			}
		}
		&-upload{
			display: inline-flex;
			align-items: center;
			margin: 0;
			cursor: pointer;
			&-icon{
				display: block;
				margin: 0 10px 0 0;
				> svg{
					fill: $color-general;
				}
			}
			&-text{
				display: block;
				color: #6a6a6a;
				font-size: 16px;
				font-weight: 400;
				border-bottom: 1px dashed $color-general-dark;
			}
		}
		&-input{
			width: 0.1px;
			height: 0.1px;
			opacity: 0;
			overflow: hidden;
			position: absolute;
			z-index: -1;
		}
	}
	&__loader, &__success{
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		// background-color: rgba(245, 248, 251, 0.5);
	}
	&__loader{
		align-items: center;
		justify-content: center;
		img{
			width: 50px;
			height: 50px;
			animation: rotate_preloader 2.5s infinite linear;
		}
		> svg{
			fill: $color-general;
			animation: rotate_preloader 2.5s infinite linear;
		}
	}
	&__success{
		align-items: center;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		padding: 0 15px;
		&-icon{
			fill: $color-general;
		}
		&-title{
			display: block;
			padding: 15px 0 0;
			color: #6a6a6a;
			font-size: 24px;
			line-height: 30px;
		}
		&-desc{
			display: block;
			padding: 5px 0 0;
			color: #6a6a6a;
			font-size: 16px;
			line-height: 20px;
		}
	}
}

@media (max-width: 565px){
	.simple-form{
		$this: &;
		&__row{
			flex-direction: column;
			#{$this}__input{
				min-width: 100%;
				width: 100%;
			}
		}
		&__acceptance{
			text-align: center;
		}
		&__textarea{
			height: 120px;
		}
	}
}

@keyframes rotate_preloader {
	0% {
	  transform: rotate(-360deg);
	}
  
	100% {
	  transform: rotate(0deg);
	}
}