.product-price{
	&__price-result{
		display: block;
		color: $color-general-dark;
		font-size: 14px;
		font-weight: 400;
	}
	
	&__status{
		display: block;
		font-size: 14px;
		font-weight: 400;
		&_available{
			color: #5cb85c;
		}
	}
	&__price{
		color: #333	;
		font-size: 36px;
		font-weight: 700;
		line-height: 36px;
		// &_sell-price{
		// 	color: #333;
		// }
	}
	&__old_price{
		display: inline-block;
		color: #999;
		font-size: 20px;
		font-weight: 500;
	}
	
}

@media(max-width: 575px){
	.product-price{
		padding-bottom: 10px;
		&__price{
			&_sell-price{
				font-size: 20px;
			}
		}
	}
}