.section-slider{
	position: relative;
	height: 560px;
	overflow: hidden;
	&__slides{
		transition: .3s;
		&:not(.slick-initialized){
			opacity: 0;
		}
	}
	&__slide{
		background-position: center;
		&-wrapper{
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-end;
			padding: 0 0 12%;
			height: 560px;
		}
		&-title{
			display: inline-block;
			padding: 5px 30px;
			background-color: #fff;
			color: $color-general;
			font-size: 70px;
			font-weight: 700;
		}
		&-desc{
			display: inline-block;
			padding: 5px 30px 20px;
			background-color: #fff;
			color: $color-general;
			font-size: 34px;
			font-weight: 700;
			line-height: 40px;
		}
		&-button{
			margin: 50px 0 0;
		}
	}
	&__arrow{
		display: flex !important;
		align-items: center;
		position: absolute;
		z-index: 20;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 108px !important;
		height: 108px;
		border: none;
		background-color: transparent;
		background-image: url('/static/images/styled-square.svg');
		background-position: center;
		background-size: 100%;
		transition: .3s;
		cursor: pointer;
		$arrow: &;
		&:hover{
			
			&.section-slider__arrow_prev{
				left: -55px;
			}
			&.section-slider__arrow_next{
				right: -55px;
			}
		}
		&_prev{
			justify-content: flex-end;
			padding-right: 25px;
			left: -60px;
		}
		&_next{
			justify-content: flex-start;
			padding-left: 25px;
			right: -60px;
			> svg{
				transform: rotate(180deg);
			}
		}
		> svg{
			fill: #fff;
		}
	}
	.slick{
		&-dots{
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			position: absolute;
			bottom: -10px;
			left: 0;
			width: 100%;
			li{
				width: 8px;
				height: 5px;
				margin: 0 5px;
				background-color: #ddd;
				transition: .3s;
				&.slick-active{
					width: 38px;
					background-color: $color-general;
				}
				button{
					display: none;
				}
			}
		}
	}
}

@media(max-width: 1199px){
	.section-slider{
		height: 42vw;
		&__slide{
			background-size: auto 100%;
			background-position: center;
			&-title{
				font-size: 54px;
			}
			&-wrapper{
				height: 42vw;
				padding: 0 45px 12%;
			}
			&-button{
				margin: 25px 0 0;
			}
		}
	}
}

@media(max-width: 991px){
	.section-slider{
		// height: 46vw;
		height: 50vw;
		&__slide{
			&-title{
				padding: 5px 20px;
				font-size: 42px;
			}
			&-desc{
				padding: 5px 20px 10px;
				font-size: 28px;
				line-height: 36px;
			}
			&-wrapper{
				height: 46vw;
				padding: 0 0 12%;
			}
			&-button{
				margin: 20px 0 0;
			}
		}
		&__arrow{
			display: none !important;
		}
	}
}

@media(max-width: 767px){
	.section-slider{
		
		&__slide{
			&-title{
			    padding: 2px 20px;
				font-size: 32px;
			}
			&-desc{
				padding: 0 20px 5px;
				font-size: 22px;
				line-height: 28px;
			}
			&-wrapper{
				
				padding: 0 0 8%;
			}
			&-button{
				
			}
		}
	}
}

@media(max-width: 575px){
	.section-slider{
		height: 52vw;
		&__slide{
			&-title{
				padding: 2px 8px;
				font-size: 18px;
				line-height: 22px;
			}
			&-desc{
				padding: 0 8px 5px;
				font-size: 10px;
				line-height: 14px;
			}
			&-wrapper{
				margin: 0 0 0 -8px;
				padding: 0 0 12%;
			}
			&-button{
				margin: 10px 0 0 0;
				width: 100px !important;
				height: 25px !important;
				.arrow-button{
					&__text{
						font-size: 8px;
					}
					&__arrow{
						> svg{
							width: 20px;
   							height: 12px;
						}
					}
				}
			}
		}
	}
}