.catalog-block{
	display: block;
	margin: 15px 0;
	$this: &;

	&:hover{
		#{$this}__thumbnail{
			&-hover{
				opacity: 1;
			}
		}
		#{$this}__info{
			background-color: $color-general;
		}
		#{$this}__title{
			color: #fff;
		}
		#{$this}__price{
			color: #fff;
		}
		.slider-button{
			background-color: #fff;
			&__arrow{
				> svg{
					fill: $color-general-dark;
				}
			}
			&__text{
				color: $color-general;
			}
		}
	}

	&__thumbnail{
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		// height: 180px;
		height: 220px;
		background-position: center;
		background-size: cover;
		&-hover{
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-position: center;
			background-size: cover;
			opacity: 0;
			transition: opacity .3s ease-in-out;
		}
		&_none{
			background-color: #dbdbdb;
		}
		&-icon{
			width: 30px;
            height: 30px;
            fill: #efefef;
		}
	}
	&__info{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100px;
    	padding: 15px 20px;
		// height: 140px;
		// padding: 20px 20px;
		background-color: #f5f8fb;
		transition: .2s;
	}
	&__title{
		display: block;
		color: #333;
		font-size: 16px;
		font-weight: 400;
		transition: .2s;
	}
	&__footer{
		display: flex;
		justify-content: space-between;
	}
	&__price{
		color: #333;
		font-size: 20px;
		font-weight: 700;
		transition: .2s;
	}
	&__button{
		width: 110px;
		height: 30px;
	}
}