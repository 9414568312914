.sidebar-helper{
	display: block;
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 320px;
	max-width: 270px;
	margin: 30px 0 0;
	&:after{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(122, 221, 216, 0.88);
	}
	&__wrapper{
		position: absolute;
		z-index: 2;
		max-width: calc(100% - 32px);
		bottom: 35px;
		left: 20px;
	}
	&__title{
		display: block;
		color: #fff;
		font-size: 26px;
		font-weight: 700;
		line-height: 28px;
	}
	&__button{
		display: inline-block;
		padding: 6px 28px;
		margin: 15px 0 0;
		color: $color-general-dark;
		font-size: 16px;
		line-height: 21px;
		border-radius: 18px;
		border: 1px solid #fff;
		background-color: #fff;
		transition: .3s;
		&:hover{
			background-color: transparent;
			color: #fff;
		}
	}
}