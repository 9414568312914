.shop-button {
    flex: 0 0 80px;
    display: flex;
    align-items: center;
	justify-content: center;
	padding: 10px 13px;
    color: #fff;
    font-weight: 400;
    font-size: 14px;
	$this: &;

	&__wrapper{
		position: relative;
	}

    &__icon {
		fill: #6a6a6a;
		transition: .3s;
    }

    &__count {
        display: inline-flex;
        justify-content: center;
		align-items: center;
		position: absolute;
		top: -2px;
		right: -10px;
        width: 21px;
        height: 21px;
		border-radius: 100%;
		font-size: 12px;
		color: #fff;
		font-weight: 900;
        background-color: $color-general;
        transform: scale(0);
        transition: color 100ms ease, margin-left 200ms ease 100ms, transform 200ms ease 100ms;

        &.filled {
            transform: scale(1);
            transition: color 100ms ease 200ms, margin-left 200ms ease, transform 200ms ease;
        }
    }

    &:hover {
		#{$this}__icon{
			fill: $color-general;
		}
		#{$this}__count.filled{
			transform: translate3d(3px, -5px, 0);
		}
    }
}