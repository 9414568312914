.cart {
    &-table {
        border: 1px solid #DDDDDD;
        border-radius: 4px;
		overflow: hidden;
		$table: &;

        &-head {
            display: flex;
            align-items: center;
            height: 30px;
            background-color: #eceeef;
            border-bottom: 1px solid $color-border;
            padding-left: 20px;

            &__title {
                font-size: 13px;
                color: $color-caption;
            }
		}
		
		&-body{
			#{$table}-row{
				align-items: flex-start;
				padding: 15px 0;

				&__mobile-head-wrapper{
					align-items: flex-start;
				}
			}
			#{$table}-col{
				&_price, &_total{
					padding: 5px 0;
				}
				&_delete{
					height: 30px;
				}
			}
		}

        &-row {
			display: flex;
			align-items: center;
            width: 100%;
            height: 100%;
            padding: 5px 0;
			
			&__mobile-head-wrapper{
				display: flex;
				align-items: center;
				flex: 1 0 auto;
			}
        }

        &-col {
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            height: 100%;

            &_price {
                flex: 0 0 auto;
                width: 135px;
			}
			
			&_options{
				flex: 0 0 auto;
                width: 300px;
			}

            &_count {
                flex: 0 0 auto;
                width: 110px;
            }

            &_total {
                flex: 0 0 auto;
                width: 130px;
            }

            &_delete {
                flex: 0 0 auto;
                width: 35px;
            }
        }
    }

    &-desc {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: $color-caption;
        border: 1px dashed $color-border;
        border-radius: 4px;
        padding: 12px 20px;
        margin-top: 20px;
    }

    &-order {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        margin-top: 30px;

        &-btn {
			min-width: 270px;
			height: 45px;
            margin-left: 30px;
        }
    }

    &-text {
        display: inline-flex;
        align-items: flex-end;
        font-weight: 500;
        color: $color-text;
    }

    &-total, &-currency {
        font-weight: 700;
        font-size: 30px;
        margin-left: 5px;
        margin-bottom: -2px;
    }
}

@media(max-width: 1199px) {
    .cart {
        &-order {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-top: 20px;

            &-btn {
                margin-top: 10px;
                margin-left: 0;
            }
        }
    }
}

@media(max-width: 991px) {
    .cart {
        &-table {
			$table: &;
			&-head{
				display: none;
			}
			&-body{
				#{$table}-row{
					align-items: flex-start;
					padding: 20px 15px;
					&__mobile-head-wrapper{
						display: block;
					}
				}
			}
			&-col{
				&_options{
					padding: 15px 0 0;
				}
				&_price, &_total{
					padding: 5px 0 0;
				}
			}
        }
    }
}

@media(max-width: 767px) {
    .cart {
        &-table {
			$table: &;
			&-body{
				#{$table}-row{
					flex-direction: column;
					padding: 0;
				}
				#{$table}-col{
					padding: 5px 15px;
					&_delete{
						height: 0;
					}
				}
			}

            &-col {
                flex: 0 1 auto;
                width: 100%;
                height: auto;
            }
        }
    }
}
