// General fonts
@mixin font-h1 {
	color: #6a6a6a;
	font-size: 36px;
	font-weight: 700;
	line-height: 44px;
}

@mixin font-h2 {
	color: #6a6a6a;
	font-size: 24px;
	font-weight: 700;
	line-height: 32px;
}

@mixin font-h3 {
	color: #6a6a6a;
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
}

@mixin font-p {
	color: #6a6a6a;
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
}

@mixin font-sign {
	color: #999;
	font-size: 14px;
	font-weight: 400;
}



