.product-slider{
	position: relative;
	&:hover{
		&:after{
			opacity: 0.8;
		}
	}
	&:after{
		content:"";
		position: absolute;
		right: 16px;
		top: 16px;
		width: 44px;
		height: 44px;
		border-radius: 100%;
		background-repeat: no-repeat;
		background-position: center center;
		background-color: $color-general;
		background-size: 19px 19px;
		background-image: $whiteZoomIcon;
		transition: .3s;
		pointer-events: none;
	}
	&__container{
		position: relative;
		border: 1px solid #e5e5e5;
		background-color: #fff;
		overflow: hidden;
		transition: .3s;
		&:not(.slick-initialized){
			height: 381px;
			opacity: 0;
		}
		
	}
	&__container-slide{
		display: flex !important;
		align-items: center;
		justify-content: center;
		height: 380px;
		outline: none;
		// &:hover{
		// 	&:after{
		// 		background-color: $color-general;
		// 	}
		// }
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		// &:after{
		// 	content:"";
		// 	position: absolute;
		// 	right: 16px;
    	// 	top: 16px;
		// 	width: 44px;
		// 	height: 44px;
		// 	border-radius: 100%;
		// 	background-repeat: no-repeat;
		// 	background-position: center center;
		// 	background-color: rgba(1, 116, 216, 0.3);
		// 	background-size: 19px 19px;
		// 	background-image: $whiteZoomIcon;
		// 	transition: .3s;
		// }
	}
	&__carousel{
		padding-top: 5px;
		overflow: hidden;
		transition: .3s;
		&:not(.slick-initialized){
			height: 100px;
			opacity: 0;
		}
		.slick-list{
			margin: 0 -3px;
			.slick-track{
				min-width: 100%;
			}
		}
		.slick-slide{
			margin: 0 3px;
			&.slick-current{
				.product-slider__carousel-item{
					border-color: $color-general;
				}
			}
		}
	}
	&__carousel-item{
		display: flex !important;
		align-items: center;
		justify-content: center;
		height: 94px;
		outline: none;
		border: 1px solid #e5e5e5;
		background-color: #fff;
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.slick-dots{
		display: flex;
		justify-content: center;
		position: absolute;
		bottom: 7px;
		width: 100%;
		li{
			&.slick-active{
				button{
					background-color: $color-general;
				}
			}
			button{
				width: 11px;
				height: 11px;
				border-radius: 100%;
				font-size: 0;
				border: none;
				box-shadow: none;
				outline: none;
				margin: 0 6px;
				padding: 0;
				background-color: #dbdbdb;
			}
		}
	}
}

@media (max-width : 991px){
	.product-slider{
		&__container{
			&:not(.slick-initialized){
				height: 65vw;
			}
		}
		&__carousel{
			&:not(.slick-initialized){
				height: 100px;
			}
		}
	}
}

@media (max-width : 767px) {
	.product-slider{
		&__container{
			&:after{
				display: none;
			}
		}
		&__container-slide{
			height: 320px;
		}
		&__carousel{
			display: none;
		}
	}
}

@media (max-width : 575px) {
	.product-slider{
		&__container{
			&:after{
				
			}
		}
		&__container-slide{
			height: 260px;
		}
		&__carousel{
			
		}
	}
}