.arrow-button{
	display: block;
	width: 200px;
	height: 50px;
	border-radius: 26px;
	background-color: $color-general;
	transition: .3s;
	overflow: hidden;
	$this: &;

	&:hover{
		background-color: #fff;
		#{$this}__wrapper{
			transform: translateX(0%);
		}
		#{$this}__arrow{
			padding: 0 20% 0 0;
		}
		#{$this}__text{
			color: $color-general-dark;
		}
	}

	&__wrapper{
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 200%;
		transform: translateX(-30%);
		transition: .3s;
	}

	&__arrow{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 50%;
		min-width: 50%;
		padding: 0 10px 0 0;
		transition: .3s;
		> svg{
			fill: $color-general-dark;
		}
	}

	&__text{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 50%;
		min-width: 50%;
		color: #fff;
		font-size: 16px;
		font-weight: 700;	
		transition: .3s;	
	}
}