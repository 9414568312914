.product-form{
	&__title{
		display: block;
		color: #6a6a6a;
		font-size: 30px;
		font-weight: 700;
		padding: 0 0 40px;
	}
	&__block{
		padding: 0 0 30px;
		&-title{
			display: block;
			padding: 0 0 8px;
			color: $color-general-dark;
			font-size: 15px;
			font-weight: 700;
			line-height: 21px;
		}
	}
	&__params{

		&-row{
			display: flex;
			padding: 8px 0 4px;
			&:nth-child(even){
				background-color: #fff;
			}
			&:nth-child(odd){
				background-color: #f5f8fb;
			}
		}
		&-title{
			display: block;
			flex: 0 0 45%;
			padding: 0 15px;
			color: #8e8e8e;
			font-size: 13px;
			font-weight: 700;
		}
		&-value{
			display: block;
			flex: 0 0 55%;
			padding: 0 15px;
			color: #3a372e;
			font-size: 16px;
			font-weight: 400;
		}
	}

	&__kit{
		padding: 20px 0;
		background-color: #f5f8fb;
		&-row{
			display: flex;
			padding: 5px 0;
		}
		&-title{
			display: block;
			flex: 0 0 45%;
			padding: 10px 25px 0;
			color: #8e8e8e;
			font-size: 13px;
			font-weight: 700;
		}
		&-value{
			display: block;
			flex: 0 0 55%;
			padding: 0 25px 0 15px;
			
		}
	}

	&__color-selector{
		display: flex;
		flex-wrap: wrap;
		margin: -2px;
	}

	&__labels{
		display: flex;
		justify-content: space-between;
		padding: 0 0 12px;
		margin: 0 0 6px;
		border-bottom: 1px solid #e5e5e5;
	}
	&__vendor-code{
		color: #818a91;
		font-size: 16px;
	}
	&__labels + &__price-row{
		border-top: none;
	}
	&__price-row{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 5px 0;
		.wrapper{
			display: flex;
			align-items: center;
			.product-amount{
				width: 140px;
				height: 48px;
				margin: 0 20px 0 0;
				&__button{
					font-size: 10px;
				}
			}
		}
	}
	&__add-to-cart{
		position: relative;
		button{
			width: 300px;
			height: 60px;
		}
	}
	&__notify-button{
		button{
			width: 110px;
			height: 48px;
		}
	}
	&__button{
		display: flex;
		align-items: center;
		margin: 0 62px 0 0;
		border: none;
		background: transparent;
		color: #999;
		font-size: 15px;
		font-weight: 400;
		line-height: 24px;
		transition: .3s;
		cursor: pointer;
		> svg{
			margin: 0 12px 0 0;
			fill: #999;
			transition: .3s;
		}
		&:hover, &.active{
			> svg{
				fill: $color-general;
			}
			color: $color-general;
		}
	}
	&__buttons-wrapper{
		display: flex;
		flex-wrap: wrap;
		padding: 10px 0;
	}
	// &__add-to-cart{
	// 	width: 300px;
	// 	height: 60px;
	// }
}


@media (max-width : 991px) {
	.product-form{
		&__title{
			padding: 12px 0;
		}
	}
}

@media(max-width: 575px){
	.product-form{
		&__title{
			line-height: 26px;
			padding: 5px 0 10px;
			font-size: 18px;
		}
		&__labels{
			flex-direction: column;
		}
		&__price-row{
			margin: 5px 0 0;
			padding: 15px 0;
			flex-direction: column;
			align-items: flex-start;
		}
		&__block{
			padding: 0 0 20px;
		}
		&__params{

			&-row{
				flex-direction: column;
			}
			&-title{
				flex: 0 0 100%;
			}
			&-value{
				flex: 0 0 100%;
			}
		}
		&__kit{

			&-row{
				flex-direction: column;
				padding: 8px 0;
			}
			&-title{
				flex: 0 0 100%;
				padding: 0 25px 5px;
			}
			&-value{
				flex: 0 0 100%;
			}
		}
	}
}