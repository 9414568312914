.header-logo {
    display: flex;
    align-items: center;
    width: 195px;
    height: 90px;

    &__image {
        max-width: 90%;
        max-height: 90%;
    }
}