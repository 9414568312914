.filters{
	&__form{
		border: 1px solid #e5e5e5;
		background-color: #fff;
	}
	
	&__footer{
		padding: 16px 12px;
		&-result{
			color: #373a3c;
			font-size: 16px;
			font-weight: 400;
			text-align: center;
			padding-bottom: 15px;
		}
		&-buttons{
			display: block;
			// input{
			// 	margin-bottom: 5px;
			// 	cursor: pointer;
			// 	height: 30px;
			// 	flex: 0 0 calc(100% / 2 - 5px);
			// 	font-size: 12px;
			// 	font-weight: 400;
			// }
			// input[type="reset"]{
			// 	color: #9b9b9b;
			// 	transition: .3s;
			// 	&:hover{
			// 		color: $color-general;
			// 		border-color: $color-general;
			// 	}
			// }
		}
		&-submit{
			display: block;
			width: 100%;
			padding: 5px 10px;
			text-align: center;
			border-radius: 20px;
			background-color: $color-general;
			border: 1px solid $color-general;
			color: #fff;
			font-size: 16px;
			font-weight: 700;
			transition: .3s;
			&:hover{
				background-color: transparent;
				color: $color-general;
			}
		}
		&-reset{
			display: block;
			margin: 7px auto;
			color: $color-general;
			font-size: 14px;
			font-weight: 400;
			border: none;
			border-bottom: 1px dashed $color-general;
			background-color: transparent;
			transition: .3s;
			cursor: pointer;
			&:hover{
				color: $color-general-dark;
				border-bottom-color: $color-general-dark;
			}
		}
	}

	&__mobile-footer{
		display: flex;
		flex-direction: column;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 100px;
		padding: 16px 12px;
		border-top: 1px solid #e5e5e5;
	}
	&__mobile-result{
		color: #373a3c;
		font-size: 16px;
		font-weight: 400;
		text-align: center;
		padding-bottom: 15px;
	}
	&__mobile-controllers{
		display: flex;
		justify-content: space-between;
		button{
			margin-bottom: 5px;
			cursor: pointer;
			height: 30px;
			flex: 0 0 calc(100% / 2 - 5px);
			font-size: 15px;
			font-weight: 400;
			line-height: 21px;
			border-radius: 15px;
		}
	}
	&__mobile-reset{
		color: #373a3c;
		border: 1px solid #ccc;
		background-color: #fff;
		border-radius: 4px;
		transition: .3s;
		&:hover{
			color: $color-general;
			border-color: $color-general;
		}
	}
}



@media (max-width: 1199px) {
	.filters{
		background-color: #fff;
		position: fixed;
		z-index: 800;
		width: 270px;
		height: 100%;
		right: 0;
		top: 0;
		overflow-y: auto;
		transform: translateX(110%);
		transition: .3s;
		&.opened{
			transform: translateX(0%);
		}
		&__footer{
			display: none;
		}
		&__mobile-wrapper{
			height: calc(100% - 100px);
			overflow-y: auto;
		}
	}
}