.footer-social-icon{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 100%;
	border: 1px solid #e1e9f2;
	transition: .2s;
	> svg{
		fill: $color-general;
		transition: .2s;
	}
}