.requisite-block {
	margin: 0 0 13px;
	&__title {
		display: block;
		color: $color-general;
		font-size: 12px;
		font-weight: 600;
	}
	&__value {
		display: block;
		color: #373a3c;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
	}
}