.load_content{
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 25px 0 30px;
	width: 100%;
	height: 50px;
	color: $color-general-dark;
	font-size: 16px;
	font-weight: 700;
	border-radius: 26px;
	border: 1px solid #cfd1d4;
	background-color: #fff;
}
@media (max-width: 767px) {
  
	.load_content {
		margin: 0 0 15px;
		height: 40px;
		font-size: 16px;
	}
}