.product-tabs{
	.nav{

		&-item{
			margin-right: 3px;
		}
		&-link{
			background-color: #f5f8fb;
			border: 1px solid #eceeef;
			&.active{
				color: $color-general;
				background-color: #fff;
			}
		}
	}
}