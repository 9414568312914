.radio-color{
	display: block;
	margin: 2px;
	cursor: pointer;
	&__input{
		display: none;
	}
	&__input:checked + &__circle{
		border-color: $color-general;
		&:after, &:before{
			background-color: $color-general;
		}
	}
	&__circle{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 35px;
		height: 35px;
		border-radius: 18px;
		border: 2px solid #d8dfe7;
		transition: .3s;

		&_empty{
			position: relative;
			background-color: #fff;
			&:after, &:before{
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				margin: auto;
				width: 13px;
				height: 1px;
				background-color: #d8d8d8;
				transition: .3s;
			}
			&:before{
				transform: rotate(-45deg);
			}
			&:after{
				transform: rotate(45deg);
			}
		}
	}
}