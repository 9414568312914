.select-field{
	position: relative;
	width: 100%;
	height: 35px;
	cursor: pointer;
	&:after{
		content: ''; 
		position: absolute;
		z-index: 2;
		right: 14px;
		top: 16px;
		border: 3px solid transparent;
		border-top: 4px solid #bbc1c6;
		transition: .3s;
	}

	&:hover{
		&:after{
			border-top-color: #000;
		}
	}

	select{
		width: 100%;
		height: 100%;
		padding: 0 30px 0 15px;
		border-radius: 18px;
		border: 1px solid #d8dfe7;
		background-color: #fff;
		color: #3a372e;
		font-size: 13px;
		font-weight: 400;
		outline: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		-ms-appearance: none;
		appearance: none !important;
		&:focus, &:active{
			box-shadow: none !important;
			border-color: #d8dfe7;
		}
		&::-ms-expand {
			display: none;
		}
	}
	
	option{

	}
}