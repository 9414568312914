.certificate {
	&-thumbnail {
		position: relative;
		display: block;
		width: calc(100% - 24px);
		height: 174px;
		border: 1px solid $color-border;
		border-bottom-color: $color-border;
		margin: 6px 0 5px;
		background-position: center top;
		background-size: cover;
		&:before {
			content: '';
			position: absolute;
			right: -27px;
			bottom: 0;
			width: 26px;
			height: 36px;
			border-top: 36px solid #d9d9d9;
			border-right: 26px solid transparent;
		}
		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0;
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-position: center center;
			background-color: rgba(122, 221, 216, 0.84);
			background-size: 30px 30px;
			background-image: $whiteZoomIcon;
			transition: .3s;
		}
		&:hover:after {
			opacity: 1;
		}
		&.w_certificates{
			min-height: 174px;
			object-fit: cover;
		}
	}
	&-sign {
		p {
			display: block;
			@include font-sign;
		}
	}
}




@media(max-width: 767px) {
	@import "../media-mixins";

	.certificate {
		padding: 0 0 8px;
		&-thumbnail {
			margin: 5px 0;
		}
		&-sign {
			p {
				@include font-sign;
			}
		}
	}
}