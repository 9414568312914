.extra-products-section{
	padding: 35px 0;
}

.extra-products-slider{
	padding: 30px 0;
	&.active &{
		&__slider-header{
			.nav-buttons{
				display: flex;
			}
		}
	}
	&__slider-header{
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		padding: 0 0 25px;
		&-title{
			display: block;
			color: #6a6a6a;
			font-size: 36px;
			font-weight: 700;
			> svg{
				fill: $color-general;
			}
		}
		.nav-buttons{
			display: none;
			justify-content: space-between;
			margin: 0 -2.5px;
			a{
				display: flex;
				justify-content: center;
				align-items: center;
				width: 30px;
				height: 30px;
				margin: 0 2.5px;
				border-radius: 15px;
				border: 1px solid #dcdee1;
				background-color: #fff;
				overflow: hidden;
				transition: .3s;
				&:hover{
					width: 55px;
					border-color: $color-general;
					span{
						width: 30px;
						background-color: $color-general;
						&:after{
							right: 2px;
							border-color: $color-general;
						}
					}
				}
				&.slick-hidden{
					display: none;
				}
				&.prev{
					span{
						transform: rotate(180deg);
					}
				}
				
				span{
					display: block;
					position: relative;
					width: 0px;
					height: 1px;
					background-color: transparent;
					transition: .3s;
					&:after{
						content: "";
						position: absolute;
						top: -4px;
						bottom: 0;
						right: -2px;
						width: 8px;
						height: 8px;
						border: 1px solid #dcdee1;
						border-width: 1px 1px 0 0;
						transform: rotate(45deg);
						transition: .3s;
					}
				}
			}
		}
	}
	&__slider-body{
		overflow: hidden;
		height: 400px;
		transition: .3s;
		opacity: 0;
		&.slick-initialized{
			height: auto;
			opacity: 1;
		}
		.slick-list{
			margin: 0 -15px;
			overflow-y: visible;
			overflow-x: hidden;
			.slick-track{
				min-width: 100%;
			}
		}
		.slick-slide{
			margin: 0 15px;
			.catalog-block{
				margin: 0;
			}
		}
	}
	&__no-slider{
		padding: 15px 0;
	}
	.slick-dots{
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		li{
			width: 8px;
			height: 5px;
			margin: 0 5px;
			background-color: #ddd;
			transition: .3s ease-in-out;
			cursor: pointer;
			&.slick-active{
				width: 38px;
				background-color: $color-general;
			}

			button{
				display: none;
			}
		}
	}
}

@media(max-width: 767px){
	.extra-products-section{
		padding: 0;
	}
	.extra-products-slider{
		padding: 22px 0;
		&.active &{
			&__slider-header{
				.nav-buttons{
					display: none;
				}
			}
		}
		

		&__slider-body{
			margin: 0 -15px;
			padding: 0 0 15px;
			
			.slick-list{
				margin: 0;
				padding: 0 10px;
				overflow: visible;
				position: relative;
				&:after{
					content: "";
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					width: 30vw;
					// width: 0vw;
					background: -moz-linear-gradient(left, rgba(249,249,249,0) 0%, rgba(242,242,242,1) 100%);
					background: -webkit-linear-gradient(left, rgba(249,249,249,0) 0%,rgba(242,242,242,1) 100%);
					background: linear-gradient(to right, rgba(249,249,249,0) 0%,rgba(242,242,242,1) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f9f9f9', endColorstr='#f2f2f2',GradientType=1 );
					pointer-events: none;
				}
			}
			.slick-track{
				// padding: 0 15px;
			}
			.slick-slide{
				width: 270px;
				margin: 0 5px;
				.catalog-block{
					
				}
			}
		}
	}
}

@media(max-width: 575px){
	.extra-products-slider{
		&__slider-header{
			flex-direction: column;
			align-items: flex-start;
			padding: 0 0 12px;
			&-title{
				font-size: 20px;
				> svg{
					width: 25px;
					height: 20px;
				}
			}
		}
	}
}