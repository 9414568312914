.section-callback{
	background-color: #f5f8fb;
	background-image: url('../images/callback-section-img1.png'), url('../images/callback-section-img2.png');
	background-position: center right +50vw, center left +50vw;
	background-size: auto 100%;
	background-repeat: no-repeat;

	&__form-block{
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 600px;
		padding: 30px 70px 0;
	}

	&__title{
		display: block;
		padding: 0 0 25px;
		color: #6a6a6a;
		font-size: 36px;
		font-weight: 700;
		line-height: 42px;
	}
}


@media(max-width: 1199px){
	.section-callback{

		&__form-block{
			padding: 0 35px;
		}
	}
}

@media(max-width: 991px){
	.section-callback{
		background-position: center top, center top +62vw;
		background-size: 100% 62vw, 100% auto;
		padding: 70vw 0 20px;
		&__form-block{
			width: 470px;
			max-width: 100%;
			margin: auto;
			padding: 0;
		}
	}
}

@media(max-width: 575px){
	.section-callback{
		padding: 80vw 0 20px;
		&__title{
			padding: 0 0 15px;
			font-size: 24px;
			line-height: 30px;
		}
		&__form-block{
			height: auto;
		}
	}
}