.review-section{
	padding: 0 0 60px;
	overflow: hidden;
	$section: &;

	&.done{
		#{$section}__phone{
			opacity: 1;
			transform: translateY(88px);
			&-popup{
				transform: scale(1);
			}
		}
	}
	&__phone{
		opacity: 0;
		transform: translateY(100%);
		transition: opacity .6s ease-in, transform .6s ease-in;
		&-popup{
			transform: scale(0);
			transition: transform .5s ease-in;
		}
	}

	&__title{
		display: flex;
		padding: 0 0 25px;
		color: #6a6a6a;
		font-size: 36px;
		font-weight: 700;
		> svg{
			margin: 14px 10px 0 0;
			stroke: $color-general;
			fill: #fff;
		}
	}
	&__phone{
		position: relative;
		margin: 0 auto -88px;
		width: 304px;
		// transform: translateY(88px);
		&-popup{
			display: block;
			position: absolute;
			&#popup-1{
				top: 40px;
				left: -20px;
				transition-delay: .3s;
			}
			&#popup-2{
				top: -40px;
				right: -65px;
				transition-delay: .9s;
			}
			&#popup-3{
				top: 115px;
				left: -50px;
				transition-delay: 1.2s;
			}
			&#popup-4{
				top: 170px;
				right: -12px;
				transition-delay: .6s;
			}
		}

	}
}

@media(max-width: 767px){
	.review-section{

		&__phone{
			display: none;
		}
	}
}

@media(max-width: 575px){
	.review-section{
		padding: 10px 0 30px;
		&__title{
			font-size: 20px;
			padding: 0 0 8px;
			> svg{
				width: 20px;
				height: 24px;
				margin: 5px 5px 0 0;
			}
		}
	}
}