.conditions{
	border: 1px dashed #ccc;
	padding: 15px 20px;
	&__block{
		&:not(:last-child){
			border-bottom: 1px solid #e7e7e7;
			padding-bottom: 20px;
    		margin-bottom: 19px;
		}
		&-title{
			display: flex;
			align-items: center;
			padding-bottom: 6px;
			> svg{
				fill: $color-general;
				margin: 0 6px 0 0;
			}
			b{	
				display: block;
				color: #373a3c;
				font-size: 18px;
			}
		}
		&-text{
			color: #999;
			font-size: 15px;
			font-weight: 400;
			line-height: 20px;
		}
		&-link{
			display: inline-block;
			margin: 10px 0 0;
			color: $color-general-dark;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			border-bottom: 1px dashed $color-general-dark;
			transition: .3s;
			&:hover{
				color: $color-general;
				border-bottom-color: $color-general;
			}
		}
	}
}

@media (max-width : 767px) {
	.conditions{
		margin: 10px 0;
	}
}