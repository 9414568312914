
.review-slider{
	position: relative;
	height: 466px;
	background-image: url('/static/images/review-slide-bg.png');
	background-size: 100% auto;
	background-position: center top;
	$slider: &;
	&__slide{
		padding: 40px 65px 65px 40px;
		&-header{
			display: flex;
		}
		&-thumb{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 65px;
			height: 65px;
			border-radius: 100%;
			background-size: cover;
			background-position: center;
		}
		&-info{
			padding: 8px 0 0 20px;
		}
		&-date{
			display: block;
			color: $color-general-dark;
			font-size: 15px;
			font-weight: 400;
		}
		&-name{
			display: block;
			color: #333;
			font-size: 18px;
			font-weight: 700;
		}
		&-content{
			margin: 14px 0 0;
			max-height: 160px;
			p{
				color: #333;
				font-size: 15px;
				font-weight: 400;
				line-height: 22px;
			}
		}
		&-images{
			display: flex;
			flex-wrap: wrap;
		}
		&-img{
			display: block;
			position: relative;
			width: 65px;
			height: 65px;
			margin: 0 10px 10px 0;
			background-size: cover;
			background-position: center;
			cursor: pointer;
			$img: &;
			&:hover{
				#{$img}-hover{
					opacity: 1;
				}
			}
			&-hover{
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
				width: 33px;
				height: 33px;
				border-radius: 100%;
				background-color: $color-general;
				opacity: 0;
				transition: opacity .3s ease-in-out;
				&:before, &:after{
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					margin: auto;
					width: 13px;
					height: 1px;
					background-color: #fff;
				}
				&:after{
					transform: rotate(90deg);
				}
			}
		}
		&-link{
			display: none;
			align-items: center;
			margin: 14px 0 0;
			color: $color-general-dark;
			font-size: 15px;
			font-weight: 700;
			transition: .3s;
			&.active{
				display: flex;
			}
			&:hover{
				color: $color-general;
				span{
					background-color: $color-general;
					transform: translateX(10px);
					&:after{
						border-color: $color-general;
					}
				}
			}
			span{
				display: block;
				position: relative;
				margin: 2px 0 0 10px;
				width: 20px;
				height: 1px;
				background-color: $color-general-dark;
				transition: .3s;
				&:after{
					content: "";
					position: absolute;
					top: -3px;
					bottom: 0;
					right: 1px;
					width: 6px;
					height: 6px;
					border: 1px solid $color-general-dark;
					border-width: 1px 1px 0 0;
					transform: rotate(45deg);
					transition: .3s;
				}
			}
		}
	}
	.slick{
		&-slide{
			// // transform: translate3d(-15px, 15px, 0);
    		transition: opacity 0.5s ease-in-out 0s !important;
			&.slick-current{
				transition: opacity 0.5s ease-in-out .5s !important;
				// transform: translate3d(0, 0, 0);
				// #{$slider}__slide{
				// 	&-container{
						
				// 	}
				// }
			}
		}
	}
	.slick-dots{
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		position: absolute;
		bottom: -10px;
		left: 0;
		width: 100%;
		li{
			width: 8px;
			height: 5px;
			margin: 0 5px;
			background-color: #ddd;
			transition: .3s ease-in-out;
			cursor: pointer;
			&.slick-active{
				width: 38px;
				background-color: $color-general;
			}

			button{
				display: none;
			}
		}
	}
}


@media(max-width: 1199px){
	.review-slider{
		height: auto;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		&__slide{
			padding: 40px 65px 80px 40px;
		}
	}
}

@media(max-width: 575px){
	.review-slider{
		margin: 0 -10px 0 0;
		&__slide{
			padding: 15px 30px 65px 15px;
			&-thumb{
				flex: 0 0 50px;
				width: 50px;
				height: 50px;
			}
			&-info{
				padding: 4px 0 0 10px;
			}
			&-date{
				font-size: 12px;
			}
			&-name{
				font-size: 15px;
			}
			&-content{
				margin: 5px 0 0;
				max-height: 160px;
				p{
					font-size: 13px;
					line-height: 20px;
				}
			}
			&-images{
				padding: 5px 0 0;
			}
			&-img{
				width: 50px;
				height: 50px;
			}
			&-link{
				font-size: 13px;
				margin: 5px 0 0;
				span{
					margin: 2px 0 0 6px;
				}
			}
		}
	}
}