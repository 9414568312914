.footer {
    flex: 0 1 auto;
	background-color: #fff;
	border-top: 1px solid #f0f0f0;
	&-logo{
		display: block;

	}
	&-logo-desc{
		display: block;
		padding: 10px 0 0;
		color: #8e8e8e;
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
	}
	&-block{
		padding: 30px 0;
		&__title{
			display: block;
			padding: 0 0 15px;
			margin: 0 0 12px;
			color: #6a6a6a;
			font-size: 16px;
			font-weight: 700;
			border-bottom: 1px solid $color-general;
		}
		&__menu{
			li{
				a{
					padding: 4px 0;
				}
			}
		}
		&__req{
			display: flex;
			padding: 4px 0;
			&-icon{
				display: flex;
				width: 25px;
				margin: 0 5px 0 0;
				> svg{
					max-width: 100%;
					max-height: 100%;
					fill: $color-general;
				}
			}
			&_mail{
				text-decoration: underline;
			}
			&_phone{
				color: #333;
				font-size: 18px;
				font-weight: 700;
			}
		}
		a{
			transition: .2s;
			&:hover{
				color: $color-general;
			}
		}
		&__menu li a, 
		&__req span, 
		&__req a{
			display: block;
			color: #6a6a6a;
			font-size: 15px;
			font-weight: 400;
		}
	}
	&-callback{
		margin: 30px 0 0;
	}
	&__lower-part{
		margin-top: 5px;
		background-color: #f5f8fb;
		&-wrapper{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			height: 58px;
		}
		&-left{
			display: flex;
			align-items: center;
			p{
				padding-right: 55px;
			}
			a{
				text-decoration: underline;
				transition: .3s;
				&:hover{
					color: $color-general;
					text-decoration: none;
				}
			}
			p, a{
				display: block;
				color: #abbccd;
				font-size: 14px;
				font-weight: 400;
			}
		}
	}
	&__made-by{
		display: flex;
		align-items: center;
		color: #abbccd;
		font-size: 14px;
		font-weight: 400;
		transition: .3s;
		> svg{
			fill: #9b9b9b;
			margin-left: 5px;
			transition: .3s;
		}
		&:hover{
			color: #a3bd49;
		}
	}
}






@media (max-width : 991px) {
	.footer{
		&__lower-part{
			&-wrapper{
				height: auto;
				flex-direction: column;
				justify-content: center;
				padding: 10px 0;
			}
			&-left{
				flex-direction: column;
				p, a{
					padding: 4px 0;
					text-align: center;
				}
			}
		}
		
		&__made-by{
			padding: 4px 0;
			justify-content: center;
		}
	}
}

@media (max-width : 767px) {
	.footer{
		&-block{
			padding: 20px 0 8px;

			&__title{
				padding: 0 0 5px;
    			margin: 0 0 15px;
			}
		}
	}
}