.section-categories{
	padding: 60px 0;
}

.categories-block{
	padding: 30px 0;
	&__title{
		display: flex;
		margin: 10px 0 40px;

		$title: &;
		&:hover{
			#{$title}-text{
				span{
					border-bottom-color: $color-general;
				}
			}
			#{$title}-arrow{
				opacity: 1;
				transform: translateX(0);
			}
		}
		&-icon{
			margin: 10px 15px 0 0;
			> svg{
				fill: $color-general;
			}
		}
		&-text{
			color: #6a6a6a;
			font-size: 36px;
			font-weight: 700;
			line-height: 42px;
			span{
				border-bottom: 1px solid transparent;
				transition: .3s;
			}
		}
		&-arrow{
			display: block;
			position: relative;
			margin: 25px 0 0 20px;
			width: 40px;
			height: 1px;
			background-color: $color-general;
			transform: translateX(-20px);
			opacity: 0;
			transition: .3s;
			&:after{
				content: "";
				position: absolute;
				top: -6px;
				bottom: 0;
				right: 2px;
				width: 12px;
				height: 12px;
				border: 1px solid $color-general;
				border-width: 1px 1px 0 0;
				transform: rotate(45deg);
			}
		}
	}
	&__cards{
		.catalog-block{
			margin: 0;
		}
	}

	&.done{
		.presentation-block{
			opacity: 1;
			transform: translate3d(0, 0, 0);
			&__img{
				opacity: 1;
			}
		}
	}
	.presentation-block{
		opacity: 0;
		transform: translate3d(30px, 0, 0);
		transition: opacity .5s ease-in, transform .5s ease-in;
		$block: &;

		&_reversed{
			transform: translate3d(-30px, 0, 0);
		}
		&__img{
			opacity: 0;
			transition: opacity .5s ease-in .5s;
		}
	}
}


@media(max-width: 991px){
	.section-categories{
		padding: 20px 0;
	}
	.categories-block{
		$block: &;
		&:nth-child(odd){

			.presentation-block{
				&__text-container{
					margin: 0;
					width: 430px;
					background-size: cover;
				}
				&__img{
					bottom: 10px;
					top: auto;
					left: 15%;
				}
			}
		}
		&:nth-child(even){
			> .row{
				flex-direction: column-reverse;
			}
			.presentation-block{
				&__text-container{
					margin: 0 0 0 auto;
					width: 430px;
					background-size: cover;
				}
				&__img{
					right: 15%;
					left: auto;
					bottom: 10px;
					top: auto;
				}
			}
			#{$block}__title{
				$title: &;
				flex-direction: row-reverse;
				&:hover{
					.categories-block__title-arrow{
						transform: translateX(1px);
					}
				
				}
				&-icon{
					margin: 10px 0 0 15px;
				}
				&-arrow{
					margin: 25px 20px 0 0;
					transform: translateX(20px);
					
					&:after{
						left: 2px;
						right: auto;
						border-width: 0 0 1px 1px;
					}
				}
			}
		}
		// &__cards{
		// 	display: none;
		// }
	}
}

@media(max-width: 575px){
	.section-categories{
		padding: 0 0 20px;
	}
	.categories-block{
		padding: 8px 0 15px;
		// padding: 15px 0;
		$block: &;
		&:nth-child(even){
			#{$block}__title{
				$title: &;
				flex-direction: row;
				
				
			}
		}
		&:nth-child(even){
			.presentation-block{
				&__img{
					bottom: 10px;
					top: auto;
					left: 15%;
					right: auto;
				}
			}
		}
		&__title{
			margin: 0 0 10px;
			&-icon{
				margin: 0 8px 0 0 !important;
				width: 20px;
				> svg{
					max-width: 100%;
					max-height: 100%;
				}
			}
			&-text{
				font-size: 20px;
				line-height: 26px;
			}
			&-arrow{
				display: none;
			}
		}
		&__cards{
			.row{
				> div{
					&:nth-child(2){
						display: none;
					}
				}
			}
		}
	}
}