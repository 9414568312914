.filter-block{
	border-bottom: 1px solid #e5e5e5;
	&__header{
		display: block;
		position: relative;
		background-color: #f5f5f5;
		padding: 15px 13px;
		color: #373a3c;
		border-bottom: 1px solid #e5e5e5;
		font-size: 16px;
		font-weight: 600;
		line-height: 20px;
		transition: .3s;
		&:hover{
			background-color: $color-general;
			color: #fff !important;
		}
		&_colored{
			background-color: $color-general;
			border-color: $color-general;
			color: #fff !important;
			&:hover{
				color: #fff;
			}
		}
		
		&.collapsed{
			border-bottom-color:transparent;
			color: $color-general-dark;
			&:after{
				transform: rotate(0deg);
			}
		}
		&:after{
			content: "";
			position: absolute;
			right: 15px;
			top: 22px;
			display: inline-block;
			width: 0;
			height: 0;
			margin-left: .255em;
			vertical-align: .255em;
			border-top: 4px solid;
			border-right: 4px solid transparent;
			border-bottom: 0;
			border-left: 4px solid transparent;
			transform: rotate(180deg);
			transition: transform .3s linear;
		}
	}
	&__body{
		.custom-control{
			padding: 3px 0 3px 24px;
			&-label{
				&:before, &:after{
					top: 3px;
					border-radius: 0;
				}
			}
			input{
				display: none;
			}
		}
		input:not([type="submit"]){
			width: 100%;
			max-width: 100%;
			height: 30px;
			border-radius: 15px;
			border: 1px solid #ccc;
			background-color: #fff;
			color: #373a3c;
			font-size: 15px;
			font-weight: 400;
			line-height: 21px;
			padding: 0 16px;
			&::placeholder{
				color: #9b9b9b;
				font-size: 14px;
			}
		}
	}
	&__wrapper{
		padding: 10px 13px;
		overflow: hidden;
	}
	&__scroll-content{
		max-height: 185px;
		.os-content{
			padding: 0 15px 0 0 !important;
		}
	}
	&__search_field{
		margin-bottom: 14px;
	}
}