.presentation-block{
	display: block;
	position: relative;
	$this: &;

	&_reversed{

		#{$this}__img{
			left: auto;
			right: 30px;
		}
		#{$this}__text-container{
			margin: 0 auto 0 0;
		}
	}
	&__img{
		position: absolute;
		z-index: 2;
		top: 30px;
		bottom: 0;
		left: 40px;
		margin: auto;
		max-width: 80%;
		max-height: 80%;
	}
	&__text-container{
		display: block;
		height: 460px;
		width: 370px;
		max-width: 100%;
		margin: 0 0 0 auto;
		background-image: url('/static/images/presentation-block-pattern.png');
		background-position: right center;
		background-repeat: no-repeat;
		span{
			display: block;
			transform: translateY(-10px);
			color: #67d6d0;
			font-size: 69px;
			font-weight: 900;
			line-height: 70px;
			text-transform: uppercase;
		}
	}
}

@media(max-width: 991px){
	.presentation-block{
		display: none;
	}
}

@media(max-width: 575px){
	.presentation-block{

		&__text-container{
			height: 340px;
			width: 100% !important;
			span{
				font-size: 48px;
				line-height: 58px;
			}
		}
	}
}