.header {
    flex: 0 1 auto;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    transition: transform 500ms ease;
	background: #fff;
	border-bottom: 1px solid #ececec;

	&-desktop{
		transition: .3s;
		margin-bottom: -40px;
		border-bottom: 1px solid #ececec;
		&.scrolled{
			transform: translateY(-40px);
    		margin-bottom: -40px;
		}
	}

    &-top {
		&__wrapper{
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 8px 10px;
			border-bottom: 1px solid #ececec;
		}
		&__desc{
			color: #8e8e8e;
			font-size: 14px;
			font-weight: 400;
		}
		&__phone{
			display: flex;
			align-items: center;
	
			$this: &;
			&:hover{
				#{$this}-number{
					color: $color-general;
				}
			}
			&-icon{
				> svg{
					margin: 0 8px 0 0;
					fill: $color-general;
				}
			}
			&-number{
				color: #333;
				font-family: Lato;
				font-size: 16px;
				font-weight: 700;
				transition: .3s;
			}
		}
    }

    &-middle {
		background-color: #fff;
		&__wrapper{
			display: flex;
			justify-content: space-between;
			padding-left: 12px;
		}
		&__menu{
			display: flex;
			position: relative;
			&:after{
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				margin: auto;
				width: 1px;
				height: 40px;
				background-color: #ececec;
			}
			li{
				position: relative;
				overflow: hidden;
				&:hover, &.current-menu-item{
					a{
						color: $color-general;
					}
					> svg{
						bottom: -15px;
					}
				}
				a{
					display: flex;
					align-items: center;
					height: 100%;
					padding: 10px 22px;
					color: #6a6a6a;
					font-size: 16px;
					font-weight: 400;
					transition: .3s;
				}
				> svg{
					position: absolute;
					z-index: 10;
					fill: $color-general;
					width: 28px;
					height: 28px;
					left: 0;
					right: 0;
					bottom: -30px;
					margin: auto;
					transition: bottom .2s ease-in-out;
				}
			}
		}
	}
	


    &-shop {
        display: flex;
        align-items: center;
    }

    &-mobile {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ddd;
        background-color: #fff;
    }
}

@media(min-width: 1200px) {
    .header {
        &.is-hidden {
            transform: translateY(-100%);
        }

        // &.is-hidden &-bottom {
            
        // }
    }
}

  
  header .mobile-header .left-part {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
	height: 100%;
	padding: 0 0 0 10px;
  }
  
  header .mobile-header .left-part .ps-burger {
    width: 55px;
    min-width: 55px;
  }
  
  header .mobile-header .left-part .ps-burger.opened span, header .mobile-header .left-part .ps-burger.opened span:before, header .mobile-header .left-part .ps-burger.opened span:after {
    height: 2px;
  }
  
  header .mobile-header .left-part .ps-burger.opened span:before {
    top: 0;
  }
  
  header .mobile-header .left-part .ps-burger span, header .mobile-header .left-part .ps-burger span:after, header .mobile-header .left-part .ps-burger span:before {
    background-color: #373a3c;
    height: 3px;
  }
  
  header .mobile-header .left-part .ps-burger span {
    width: 20px;
  }
  
  header .mobile-header .left-part .ps-burger span:before {
    top: -7px;
  }
  
  header .mobile-header .left-part .ps-burger span:after {
    bottom: -7px;
  }
  
  header .mobile-header .left-part .logo {
    display: block;
    width: 145px;
  }
  
  header .mobile-header .left-part .logo img, header .mobile-header .left-part .logo svg {
	max-height: 90%;
    max-width: 80%;
  }
  
  header .mobile-header .right-part {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
  }
  
  header .mobile-header .right-part .mobile-search-trigger {
    position: relative;
    height: 100%;
    width: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  
  header .mobile-header .right-part .mobile-search-trigger.opened:before, header .mobile-header .right-part .mobile-search-trigger.opened:after {
    content: "";
    position: absolute;
    top: 28px;
    left: 10px;
    width: 22px;
    height: 2px;
    background: #999;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  header .mobile-header .right-part .mobile-search-trigger.opened:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  
  header .mobile-header .right-part .mobile-search-trigger.opened > svg {
    display: none;
  }
  
  header .mobile-header .right-part .mobile-search-trigger > svg {
    fill: #999;
  }
  
  header .mobile-header .right-part .mobile-cart-link {
    height: 100%;
    width: 55px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
	align-items: center;
	justify-content: center;
	border: 1px solid #f3f3f3;
	border-width: 0 1px 0 1px;
  }
  
  header .mobile-header .right-part .mobile-cart-link .icon {
    position: relative;
    height: 25px;
  }
  
  header .mobile-header .right-part .mobile-cart-link .icon > svg {
    fill: #6a6a6a;
  }
  
  header .mobile-header .right-part .mobile-cart-link .icon .number {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
	top: -4px;
    right: -8px;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background-color: $color-general;
    color: #fff;
    font-size: 10px;
    font-weight: 700;
  }


@media (max-width: 1199px) {
	header .mobile-header {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		height: 60px;
	}
}