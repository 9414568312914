.cart-item {
    $this: &;
    width: 100%;
    // height: 80px;
    border-bottom: 1px solid $color-border;
    overflow: hidden;
    will-change: height;

    &:last-child {
        border-bottom: 0;
    }

    &-link {
        display: flex;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        padding-left: 20px;

        &:hover {
            #{$this}-title {
                color: $color-general;
            }
        }
    }

    &-thumb {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        border-radius: 4px;
        margin-right: 15px;
        overflow: hidden;

        &_no-photo {
            background-color: #dcdcdc;
        }

        &__image {
            max-width: 100%;
            max-height: 100%;
        }

        &__icon {
            width: 20px;
            height: 20px;
            fill: #EFEFEF;
        }
    }

    &-title {
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        color: $color-text;
        transition: color 100ms ease;
    }

	&-option{
		display: block;
		padding: 2px 0;
		font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        color: $color-text;
	}

    &-price {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: $color-text;

        &_sale {
            color: #d9534f;
        }
    }

    &-old-price {
        font-size: 13px;
        font-weight: 500;
        color: $color-caption;
        text-decoration: line-through;
    }
    
    &-total, &-currency {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: $color-general;

        &-wrap {
            display: flex;
        }
    }

    &-currency {
        margin-left: 5px;
    }

    &-delete {
        position: relative;
        width: 100%;
        height: 100%;
        border: 0;
        border-radius: 0;
        background-color: transparent;
        cursor: pointer;

        &::before, &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 15px;
            height: 2px;
            background-color: #999;
            margin: auto;
            transition: background-color 100ms ease;
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }

        &:hover {
            &::before, &::after {
                background-color: $color-general;
            }
        }
    }
}

@media(max-width: 991px) {
	.cart-item{
		&-link{
			padding-left: 0;
		}
		&-delete{
			height: 22px;
		}
	}
}

@media(max-width: 767px) {
    .cart-item {
		position: relative;
        height: auto;

        &-link {
			align-items: flex-start;
            flex-direction: column;
            padding: 0;
		}
		
		&-title{
			font-weight: 700;
		}

		&-option{
			padding: 0;
		}

        &-thumb {
            margin: 10px 0;
        }

        &-delete {
			position: absolute;
			top: 0;
			right: 0;
			height: 35px;
			width: 36px;
        }
    }
}
