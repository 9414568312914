.catalog-menu, .catalog-submenu {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ddd;
    border-top: none;
}

.catalog-menu {
    z-index: 100;
    top: 100%;
    left: 8px;
	width: 250px;
	min-height: 280px;
	padding: 20px 0;
	background-color: $color-general;
	$menu: &;
	
    &-item {
        $this: &;
        display: block;
        transition: background-color 100ms ease;

        &__text {
            color: #fff;
			font-size: 16px;
			font-weight: 400;
            transition: color 100ms ease;
        }

        &__arrow {
            width: 6px;
            height: 9px;
            fill: #999;
            transform: rotate(180deg);
            transition: fill 100ms ease;
        }

        &:hover {
            background-color: $color-general;

            #{$this}__text {
                color: #fff;
            }

            #{$this}__arrow {
                fill: #fff;
			}
			
			#{$menu}-link{
				transform: translateX(25px);
				&:before{
					opacity: 1;
					transform: rotate(0deg);
					left: 12px;
				}
			}

            #{$menu}-picture{
				opacity: 1;
				z-index: 106;
				img{
					opacity: 1;
				}
			}
        }
    }

    &-link {
        display: flex;
        align-items: center;
		justify-content: space-between;
		position: relative;
		padding: 8px 35px;
		overflow: hidden;
		transition: .3s ease-in-out;
		&:before{
			content: "";
			position: absolute;
			top: 13px;
			left: -14px;
			width: 14px;
			height: 14px;
			transform: rotate(-180deg);
			background-image: url('/static/images/styled-square.svg');
			background-repeat: no-repeat;
			background-position: center;
			opacity: 0;
			transition: opacity .2s linear 0s, left .4s ease-out 0s, transform .4s ease-out 0s;
		}
	}
	
	&-picture{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		position: absolute;
		z-index: 105;
		top: 0;
		left: 100%;
		width: 420px;
		height: 100%;
		padding: 20px 40px;
		background-color: $color-general;
		background-image: url('/static/images/menu-bg.png');
		background-position: bottom 5px right 30px;
		background-repeat: no-repeat;
		background-size: auto 100%;
		opacity: 0;
		
		&:hover{
			opacity: 1;
			z-index: 106;
			img{
				opacity: 1;
			}
		}
		img{
			max-width: 70%;
			max-height: 100%;
			opacity: 0;
			transition: opacity .3s ease-in-out;
		}
	}
}

