.pagination{

	.page{

		&-item{
			&:first-child{
				.page-link{
					border-top-left-radius: 21px;
					border-bottom-left-radius: 21px;
					padding-left: 18px;
				}
			}
			&:last-child{
				.page-link{
					border-top-right-radius: 21px;
					border-bottom-right-radius: 21px;
					padding-right: 18px;
				}
			}
		}
		&-link{
			padding: 10px 15px;
			color: #6a6a6a;
			font-size: 16px;
			font-weight: 400;
		}
	}
}

@media(max-width: 767px){
	.pagination {
		&-item{
			&:first-child{
				.page-link{
					padding-left: 14px;
				}
			}
			&:last-child{
				.page-link{
					padding-right: 14px;
				}
			}
		}
		.page-link{
			padding: 10px;
		}
	}
}