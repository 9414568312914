.sidebar {
    $this: &;
    border: 1px solid #ddd;
    background-color: #fff;
    margin: 0 0 30px;

    &-item {
        display: block;

        &:hover {
            #{$this}-link {
                // background-color: #f5f5f5;
            }
        }

        &.current-sidebar-item, &.current-menu-item {
            #{$this}-link {
                background-color: $color-general;
                color: #fff;
            }
        }
    }

    &-link {
        display: block;
        display: flex;
        align-items: center;
        position: relative;
        color: #373a3c;
        font-size: 16px;
        font-weight: 400;
        padding: 12px 20px;
        transition: color 200ms ease, background-color 200ms ease;

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #e6e6e6;
        }
    }
}