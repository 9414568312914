.slider-button{
	display: block;
	border-radius: 16px;
	background-color: $color-general;
	overflow: hidden;
	$this: &;

	&:hover{
		#{$this}__container{
			transform: translateX(0);
		}
	}

	&__container{
		display: flex;
		align-items: center;
		width: 200%;
		height: 100%;
		transform: translateX(-50%);
		transition: .2s;
	}

	&__arrow{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50%;
		min-width: 50%;
		> svg{
			fill: #fff;
			transition: .2s;
		}
	}
	&__text{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50%;
		min-width: 50%;
		color: #fff;
		font-size: 15px;
		font-weight: 700;
		transition: .2s;
	}
}