.filter-counter{
	width: 140px;
    height: 36px;
    line-height: 32px;
    background-color: $color-general-dark;
    text-align: center;
    position: absolute;
    right: -125px;
    transition: top .3s;
    display: none;
    z-index: 99;
	text-decoration: none !important;
	input{
		background-color: transparent;
		border: none;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 99;
		opacity: 0;
		cursor: pointer;
	}
	span{
		color: #fff;
		font-size: 12px;
		font-weight: 400;
		border-bottom: 1px dashed;
		padding-bottom: 2px;
	}
	&:after{
		content: '';
		width: 8px;
		height: 8px;
		position: absolute;
		display: block;
		top: 0;
		bottom: 0;
		margin: auto;
		left: -4px;
		z-index: 9;
		background-color: $color-general-dark;
		transform: rotate(-45deg);
	}
}

@media (max-width: 1199px) {
	.filter-counter {
		display: none !important;
	}
}