@font-face {
    font-family: Lato;
    src: local('Lato Regular'), local('Lato-Regular'), url('../fonts/lato/latoregular.woff2') format('woff2'), url('../fonts/lato/latoregular.woff') format('woff'), url('../fonts/lato/latoregular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: Lato;
    src: local('Lato Bold'), local('Lato-Bold'), url('../fonts/lato/latobold.woff2') format('woff2'), url('../fonts/lato/latobold.woff') format('woff'), url('../fonts/lato/latobold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: Lato;
    src: local('Lato Black Italic'), local('Lato-BlackItalic'), url('../fonts/lato/latoblackitalic.woff2') format('woff2'), url('../fonts/lato/latoblackitalic.woff') format('woff'), url('../fonts/lato/latoblackitalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
	font-family: 'PTRoubleSans';
	src: url('../fonts/PTRoubleSans/PTRoubleSans.eot?#iefix') format('embedded-opentype'),  url('../fonts/PTRoubleSans/PTRoubleSans.otf')  format('opentype'),
		url('../fonts/PTRoubleSans/PTRoubleSans.woff') format('woff'), url('../fonts/PTRoubleSans/PTRoubleSans.ttf')  format('truetype'), url('../fonts/PTRoubleSans/PTRoubleSans.svg#PTRoubleSans') format('svg');
	font-weight: normal;
	font-style: normal;
}

.rub::after {
	content: "д";
	font-family: 'PTRoubleSans';
	margin: 0 0 0 0.3em;
}



* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    min-height: 100%;
	height: 100%;
	&.opened{
		overflow: hidden;
		height: 100%;
		position: fixed;
	}
}

body {
    min-width: 320px;
    height: 100%;
    margin: 0;
    padding: 0;
	font-family: Lato;
	&.opened {
		overflow: hidden;
		height: 100%;
		// position: fixed;
	}
}

::-webkit-input-placeholder { color: $color-placeholder; }
::-moz-placeholder          { color: $color-placeholder; }
:-moz-placeholder           { color: $color-placeholder; }
:-ms-input-placeholder      { color: $color-placeholder; }

ol, ul {
    padding: 0;
    margin: 0;
}

li {
    list-style-type: none;
}

.h1, .h2, .h3, .h4, h1, h2, h3, h4, p {
    line-height: normal;
    margin: 0;
    padding: 0;
}

a {
    color: $color-text;
    text-decoration: none;
    &:hover {
        color: $color-general;
        text-decoration: none;
    }
}

a:focus, button:focus, input:focus, textarea:focus {
    outline: 0;
    text-decoration: none;
}

// Базовая обертка сайта
.base {
    &-wrapper, &-container {
        width: 100%;
    }
    
    &-wrapper {
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }

    &-container {
        flex: 1  auto;
        overflow: initial;
        padding: {
            top: 130px;
            // bottom: 25px;
        }
    }

    &-page {
        padding-bottom: 90px;
    }
}

.main-page{
	overflow: hidden;
}

.btn:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.navbar {
    padding: 0;
}

.params-item~.params-delimeter {
    display: none;
}

.params-delimeter::before {
    content: '-';
}


//  
.mobile-search-trigger{
	svg{
		pointer-events: none;
	}
}
.nav-close{
	*{
		pointer-events: none;
	}
}



///  Bootstrap reset
.btn-primary{
	background-color: $color-general;
    border-color: $color-general;
}
.btn-primary:hover,
.btn-outline-primary:hover,
.page-item.active .page-link{
	background-color: $color-bootstrap-dark;
}
.btn-outline-primary,
.btn-primary:hover,
.btn-outline-primary:hover,
.page-item.active .page-link{
	border-color: $color-bootstrap-dark;
}
.btn-outline-primary,
.page-link,
.page-link:hover{
	color: $color-bootstrap-dark;
}
.btn.disabled, .btn:disabled{
	opacity: 1;
}






@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}

@media (max-width: 1199px) {
    .container {
        width: 100%;
        max-width: 100%;
    }

    .base {
        &-container {
            padding: {
                top: 60px;
                // bottom: 25px;
            }
        }
    }
}

