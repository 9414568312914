.product-card-wide {
    $this: &;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: 0 10px;
    border-top: 1px solid #e5e5e5;

    &:last-child {
        margin-bottom: 30px;
        border-bottom: 1px solid #e5e5e5;
    }

    &-body {
        display: flex;
        align-items: center;
        width: 61.5%;
        height: 100%;
    }

    &-thumb {
        flex: 0 0 auto;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 54px;
        height: 54px;
        border-radius: 4px;

        &_no-photo {
            background-color: #dcdcdc;
        }

        &__image {
            max-width: 100%;
            max-height: 100%;
        }

        &__icon {
            width: 18px;
            height: 18px;
            fill: #efefef;
        }
    }

    &-preview {
        display: none;
        position: absolute;
        justify-content: center;
        align-items: center;
        z-index: 200;
        bottom: 67px;
        width: 200px;
        height: 140px;
        border-radius: 4px;
        border: 6px solid $color-general;
        background-color: #fff;

        &::before,
        &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
        }

        &::before {
            border: 14px solid transparent;
            border-top: 16px solid $color-general;
            bottom: -30px;
            width: 20px;
            height: 16px;
        }

        &::after {
            border: 7px solid transparent;
            border-top: 8px solid #fff;
            bottom: -14px;
            width: 14px;
            height: 6px;
        }

        &__image {
            max-width: 100%;
            max-height: 100%;
        }
    }

    &-title {
        flex: 1 1 auto;
        display: inline-block;
        font-size: 16px;
        line-height: 20px;
        margin-left: 10px;
        color: $color-text;
    }

    &-prices {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        width: 135px;
    }

    &-price {
        font-weight: 600;
        font-size: 16px;
        color: $color-text;

        &_sale {
            color: #d9534f;
        }
    }

    &-old-price {
        font-weight: 600;
        font-size: 13px;
        color: $color-caption;
        text-decoration: line-through;
    }

    &-no-price {
        font-weight: 600;
        font-size: 14px;
        color: $color-text;
    }

    &-form {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        width: 35%;
        margin-left: 3.5%;
    }

    &-actions {
        display: flex;
        align-items: center;
        height: 100%;
    }

    &-action-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 40px;
        border: none;
        background: none;
        cursor: pointer;

        &__icon {
            width: 16px;
            height: 14px;
            fill: #dee2e6;
            transition: fill 200ms ease, transform 75ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }

        &:hover, &.active {
            #{$this}-action-btn__icon {
                fill: $color-general;
            }
        }

        &:active {
            #{$this}-action-btn__icon {
                transform: scale(1.2);
            }
        }
    }

    &-add-cart {
        margin-left: 12px;

        &-wrap {
            position: relative;
        }
    }

    &:hover & {
        &-title {
            color: $color-general;
        }
    }

    &:hover {
        .amount {
            opacity: 1;
        }

        .badges {
            opacity: 0;
        }
    }

    .amount {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 30px;
        opacity: 0;
        transition: 200ms;
        margin: auto;
    }

    .badges {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        opacity: 1;
        -webkit-transition: .2s;
        transition: .2s;
    }

    .badge {
        margin: 2px 0;
    }

    .loading-blocks &:after {
        content: "";
        position: absolute;
        z-index: 99;
        top: -1px;
        left: -1px;
        height: calc(100% + 2px);
        width: calc(100% + 2px);
        background-image: url("../images/card_horizontal.jpg");
        background-size: 100% 100%;
    }
}

.go-to-cart {
    position: absolute;
    z-index: 30;
    top: calc(100% + 20px);
    right: 0;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 210px;
    height: 90px;
    border-radius: 4px;
    background-color: #363a3b;

    &:before {
        content: "";
        position: absolute;
        border: 4px solid transparent;
        border-bottom: 5px solid #363a3b;
        right: 68px;
        bottom: 100%;
    }

    span {
        display: block;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
    }

    a {
        margin: 10px 0 0;
        width: 174px;
        height: 30px;
		font-size: 12px;
		border-radius: 15px;
    }

    .added &, .added + & {
        display: flex;
    }
}

@media (min-width: 1200px) {
	.product-card-wide {
		&:hover & {
			&-preview {
				display: flex;
			}
		}
	}
}

@media (max-width: 1199px) {
	.go-to-cart{
		display: none !important;
	}
	.product-card-wide{
		&-body{
			width: 55%;
		}
		&-form{
			width: 45%;
		}
	}
}

@media (max-width: 768px) {
    .product-card-wide {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        padding: 10px 0 5px;

        &-body {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
        }

        &-preview {
            display: none;
        }

        &-title {
            margin: 6px 0;
        }

        &-prices {
            align-items: flex-start;
        }

        &-form {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            padding: 0;
            margin: 0;
        }

        &-actions {
            flex-direction: row-reverse;
        }

        &-action-btn {
            width: 30px;
            height: 30px;
        }
		&-add-cart{
			margin-left: 0;
		}
        &-cart {
            margin-left: 0;
            margin-right: 12px;
        }

        &:hover {
            .badges {
                opacity: 1;
            }
        }

        .amount {
            position: static;
            opacity: 1;
            margin: 6px 0;
        }

        .badges {
            opacity: 1;
            flex-direction: row;
            margin: 6px 0;
        }

        .badge {
            margin: 2px 5px 2px 0;
        }
    }
}