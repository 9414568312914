// .catalog-card{
// 	border: 1px solid #ebebeb;
// 	background-color: #fff;
// 	margin: 0 0 9px;
// 	padding: 28px 40px;
// 	&__title{
// 		display: block;
// 		color: $color-general;
// 		font-size: 16px;
// 		font-weight: 700;
// 		text-transform: uppercase;
// 		letter-spacing: 0.9px;
// 	}
// 	&__link{
// 		display: block;
// 		padding: 3px 0;
// 		color: #333;
// 		font-size: 15px;
// 		font-weight: 400;
// 		transition: .2s;
// 		&:hover{
// 			color: $color-general;
// 		}
// 	}
// 	&__container{
// 		padding: 13px 0 0
// 	}
// }


// @media(max-width: 575px){
// 	.catalog-card{
// 		padding: 15px 25px;
// 		&__container{
// 			padding: 0;
// 		}
// 		&__link{
// 			padding: 5px 0 0;
// 		}
// 	}
// }


.catalog-card{
	display: flex;
	justify-content: space-between;
	height: 130px;
	margin: 0 0 10px;
	padding: 0 0 0 40px;
	background-color: #f5f8fb;
	$this: &;

	&:hover{

		#{$this}__title{
			color: $color-general;
		}
		// #{$this}__image{
		// 	background-size: 110% 110%;
		// 	img{
		// 		transform: translateX(-10px);
		// 	}
		// }
	}
	&__title{
		display: flex;
    	align-items: center;
		color: #6a6a6a;
		font-size: 24px;
		font-weight: 700;
		transition: .3s;
	}
	&__image{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		height: 100%;
		padding: 0 45px 0 0;
		flex: 0 0 24%;
		background-image: url('/static/images/catalog-card__image-pattern.png');
		background-size: 100% 100%;
		background-position: center;
		background-repeat: no-repeat;
		transition: .3s ease-in-out;
		img{
			max-width: 55%;
			max-height: 85%;
			transition: transform .3s ease-in-out;
		}
	}
}

@media(max-width: 991px){
	.catalog-card{
		&__title{
			font-size: 19px;
		}
		&__image{
			flex: 0 0 35%;
		}
	}
}

@media(max-width: 767px){
	.catalog-card{
		padding: 0;
		$this: &;
		&:hover{

			#{$this}__image{
				background-size: cover;
			}
		}
		&__title{
			padding: 0 15px;
			font-size: 16px;
		}
		&__image{
			padding: 0 15px 0 0;
			background-size: cover;
		}
	}
}

@media(max-width: 575px){
	.catalog-card{
		flex-direction: column-reverse;
		height: auto;
		&__image{
			justify-content: center;
			padding: 0;
			height: 120px;
		}
		&__title{
			padding: 20px 15px;
		}
	}
}